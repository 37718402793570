import { mapGetters, mapActions } from 'vuex';

const BackKeyEventMixin = {
  computed:{
    ...mapGetters('commonInterface', [ 'getIsBackKeyEvent' ]),
  },
  watch:{
    getIsBackKeyEvent(){
      if(this.getIsBackKeyEvent){
        this.fetchBackKeyEvent(false);
        if(this.onClickBackKey){
          this.onClickBackKey();
        }else if(this.viewModel){
          this.viewModel.onClickBackKey();
        }
      }
    },
  },
  methods:{
    ...mapActions('commonInterface', [ 'fetchBackKeyEvent' ]),
  }
}
export default BackKeyEventMixin;