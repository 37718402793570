<template>
  <PageWithLayout>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

export default {
  name: 'WorldUpdateEnd',
  components:{
    PageWithLayout
  },
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  mounted(){
    this.init();
  },
  methods:{
    init(){
      const requestEnd = () => {
        this.webEnd();
      }
      this.$store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `<strong>신청기한이 종료되었어요.</strong>다음 기회를 이용해주세요`,
        compeleteBtnText: '알겠어요',
        compeleteBtnStyle:'secondary',
        confirmCallBack: requestEnd,
      });
    },
    onClickBackKey(){
      this.webEnd();
    },
  }
}
</script>